/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { getAllBrands } from '../util/api';

const CATEGORIES = {
  dog: {
    key: 'dog',
    label: 'Dogs',
    subCategories: [
      {
        key: 'dog-food',
        label: 'Dog Food',
        subCategories: [
          {
            key: 'organic-natural-dog-food',
            label: 'Dog Organic/Natural Food',
          },
          {
            key: 'vegan-dog-food',
            label: 'Dog Vegan Food',
          },
          {
            key: 'insect-based-dog-food',
            label: 'Dog Insect-Based Food',
          },
          {
            key: 'dry-dog-food',
            label: 'Dog Dry Food',
          },
          {
            key: 'wet-dog-food',
            label: 'Dog Wet Food',
          },
          {
            key: 'veterinary-dog-food',
            label: 'Dog Veterinary Food',
          },
          {
            key: 'puppy-food',
            label: 'Dog Puppy Food',
          },
          {
            key: 'adult-dog-food',
            label: 'Dog Adult Food',
          },
          {
            key: 'senior-dog-food',
            label: 'Dog Senior Food',
          },
          {
            key: 'small-breed-dog-food',
            label: 'Dog Small Breed Food',
          },
          {
            key: 'large-breed-dog-food',
            label: 'Dog Large Breed Food',
          },
          {
            key: 'breed-specific-dog-food',
            label: 'Dog Breed-Specific Food',
          },
          {
            key: 'hypoallergenic-dog-food',
            label: 'Dog Hypoallergenic Food',
          },
        ]
        ,
      },
      {
        key:'dog-food-storage',
        label:'Dog Food Storage',
      },
      {
        key: 'dog-treat-chews',
        label: 'Dog Treats & Chews',
        subCategories: [
          {
            key: 'dog-treats',
            label: 'Dog Treats',
          },
          {
            key: 'dog-chews',
            label: 'Dog Chews',
          },
          {
            key: 'dog-biscuits',
            label: 'Dog Biscuits',
          },
        ],
      },
      {
        key: 'dog-beds',
        label: 'Dog Beds',
        subCategories: [
          {
            key: 'dog-box-beds',
            label: 'Dog Box Beds',
          },
          {
            key: 'dog-dog-mattresses-pillows',
            label: 'Dog Mattresses & Pillows',
          },
          {
            key: 'dog-orthopaedic-beds',
            label: 'Dog Orthopaedic & Senior Beds',
          },
          {
            key: 'dog-self-heating-beds',
            label: 'Dog Self Heating Beds',
          },
          {
            key: 'dog-crate-mats',
            label: 'Dog Crate Mats',
          },
          {
            key: 'dog-sofa-beds',
            label: 'Dog Sofa Beds',
          },
        ],
      },
      {
        key: 'dog-toys',
        label: 'Dog Toys',
        subCategories: [
          {
            key: 'dog-chew-toys',
            label: 'Dog Chew Toys',
          },
          {
            key: 'dog-plush-toys',
            label: 'Dog Plush Toys',
          },
          {
            key: 'dog-fetch-toys',
            label: 'Dog Fetch Toys',
          },
          {
            key: 'dog-noisy-toys',
            label: 'Dog Noisy Toys',
          },
          {
            key: 'dog-rope-tug-toys',
            label: 'Dog Rope & Tug Toys',
          },
          {
            key: 'dog-interactive-toys',
            label: 'Dog Interactive Toys',
          },
          {
            key: 'dog-puppy-toys',
            label: 'Dog Puppy Toys',
          },
        ],
      },
      {
        key: 'dog-clothing-accessories',
        label: 'Dog Clothing & Accessories',
        subCategories: [
          {
            key: 'all-dog-clothing-accessories',
            label: 'All Dog Clothing & Accessories',
          }
        ]
      },
      {
        key: 'dog-bowls-feeders',
        label: 'Dog Bowls & Feeders',
        subCategories: [
          {
            key: 'dog-feeders',
            label: 'Dog Feeders',
          },
          {
            key: 'dog-raised-feeders',
            label: 'Dog Raised Feeders',
          },
          {
            key: 'dog-slow-feeders',
            label: 'Dog Slow Feeders',
          },
          {
            key: 'dog-drinking-fountains',
            label: 'Dog Drinking Fountains',
          },
          {
            key: 'dog-travel-bowls',
            label: 'Dog Travel Bowls',
          },
          {
            key: 'dog-bowls',
            label: 'Dog Bowls',
          },
          {
            key: 'dog-feeding-mats',
            label: 'Dog Feeding Mats',
          },
          {
            key: 'dog-food-storage',
            label: 'Dog Food Storage',
          },
        ],
      },
      {
        key: 'dog-grooming',
        label: 'Dog Grooming',
        subCategories: [
          {
            key: 'dog-brushes-and-combs',
            label: 'Dog Brushes & Combs',
          },
          {
            key: 'dog-shampoo-and-bathing-products',
            label: 'Dog Shampoo & Bathing Products',
          },
          {
            key: 'dog-grooming-tools',
            label: 'Dog Grooming Tools',
          },
          {
            key: 'dog-nail-and-paw-care',
            label: 'Dog Nail & Paw Care',
          },
        ],
      },
      {
        key: 'dog-collars-leads-harnesses',
        label: 'Dog Collars, Leads & Harnesses',
        subCategories: [
          {
            key: 'dog-collars',
            label: 'Dog Collars',
          },
          {
            key: 'dog-leads',
            label: 'Dog Leads',
          },
          {
            key: 'dog-harnesses',
            label: 'Dog Harnesses',
          },
        ],
      },
      {
        key: 'dog-travel',
        label: 'Dog Travel',
        subCategories: [
          {
            key: 'dog-travel-crates-carriers',
            label: 'Dog Travel Crates & Carriers',
          },
          {
            key: 'dog-ramps-and-steps',
            label: 'Dog Ramps & Steps',
          },
          {
            key: 'dog-seat-belts-and-protection',
            label: 'Dog Seat Belts & Protection',
          },
          {
            key: 'dog-seat-covers-and-travel-beds',
            label: 'Dog Seat Covers & Travel Beds',
          },
          {
            key: 'dog-stress-relief-and-calming',
            label: 'Dog Stress Relief & Calming',
          },
          {
            key: 'dog-travel-bags-backpacks',
            label: 'Dog Travel Bags & Backpacks',
          },
          {
            key: 'dog-travel-bowls-and-bottles',
            label: 'Dog Travel Bowls & Bottles',
          },
        ],
      },
      {
        key: 'dog-outdoor',
        label: 'Dog Outdoor',
        subCategories: [
          {
            key: 'all-dog-outdoor',
            label: 'All Dog Outdoor',
          }
        ]
      },
      {
        key: 'dog-training',
        label: 'Dog Training',
        subCategories: [
          {
            key: 'dog-training-pads',
            label: 'Dog Training pads',
          },
        ],
      },
      {
        key: 'dog-cleaning-and-hygiene',
        label: 'Dog Cleaning & Hygiene',
        subCategories: [
          {
            key: 'dog-poop-bags-and-holders',
            label: 'Dog Poop Bags & Holders',
          },
          {
            key: 'dog-cleaning-sprays',
            label: 'Dog Cleaning Sprays',
          },
          {
            key: 'dog-cleaning-products',
            label: 'Dog Cleaning Products',
          },
          {
            key: 'dog-wipes',
            label: 'Dog Wipes',
          },
          {
            key: 'dog-mats-for-muddy-paws',
            label: 'Dog Mats for Muddy Paws',
          },
        ],
      },
      {
        key: 'dog-supplments',
        label: 'Dog Supplments',
        subCategories: [
          {
            key: 'all-dog-supplments',
            label: 'All Dog Supplments',
          }
        ]
      },
    ],
  },
  cat: {
    key: 'cat',
    label: 'Cats',
    subCategories: [
      {
        key: 'cat-food',
        label: 'Cat Food',
        subCategories: [
          {
            key: 'dry-cat-food',
            label: 'Cat Dry Food',
          },
          {
            key: 'wet-cat-food',
            label: 'Cat Wet Food',
          },
          {
            key: 'veterinary-cat-food',
            label: 'Cat Veterinary Food',
          },
          {
            key: 'kitten-food',
            label: 'Cat Kitten Food',
          },
          {
            key: 'adult-cat-food',
            label: 'Cat Adult Food',
          },
          {
            key: 'senior-cat-food',
            label: 'Cat Senior Food',
          },
          {
            key: 'high-protein-cat-food',
            label: 'Cat High-Protein Food',
          },
          {
            key: 'grain-free-cat-food',
            label: 'Cat Grain-Free Food',
          },
          {
            key: 'hypoallergenic-cat-food',
            label: 'Cat Hypoallergenic Food',
          },
          {
            key: 'vegan-cat-food',
            label: 'Cat Vegan Food',
          },
          {
            key: 'insect-based-cat-food',
            label: 'Cat Insect-based Food',
          },
        ],
      },
      {
        key:'cat-food-storage',
        label:'Cat Food Storage',
      },
      {
        key: 'cat-treats',
        label: 'Cat Treats',
        subCategories: [{
          key: 'all-cat-treats',
          label: 'All Cat Treats',
        }],
      },
      {
        key: 'cat-litter',
        label: 'Cat Litter',
        subCategories: [
          {
            key: 'cat-clumping-litter',
            label: 'Cat Clumping Litter',
          },
          {
            key: 'cat-biodegradable-litter',
            label: 'Cat Biodegradable Litter',
          },
          {
            key: 'cat-clay-litter',
            label: 'Cat Clay Litter',
          },
          {
            key: 'cat-paper-litter',
            label: 'Cat Paper Litter',
          },
          {
            key: 'cat-silica-litter',
            label: 'Cat Silica Litter',
          },
          {
            key: 'cat-wood-litter',
            label: 'Cat Wood Litter',
          },
        ],
      },
      {
        key: 'cat-litter-trays',
        label: 'Cat Litter Trays',
        subCategories: [
          {
            key: 'all-cat-litter-trays',
            label: 'All Cat Litter Trays',
        }]
      },
      {
        key: 'cat-activity',
        label: 'Cat Activity',
        subCategories: [
          {
            key: 'cat-trees',
            label: 'Cat Trees',
          },
          {
            key: 'cat-scratchers',
            label: 'Cat Scratchers',
          },
          {
            key: 'cardboard-scratchers',
            label: 'Cat Cardboard Scratchers',
          },
          {
            key: 'cat-exercise',
            label: 'Cat Exercise',
          },
          {
            key: 'cat-furniture',
            label: 'Cat Furniture',
          },
        ],
      },
      {
        key: 'cat-toys',
        label: 'Cat Toys',
        subCategories: [
          {
            key: 'cat-teaser-toys',
            label: 'Cat Teaser Toys',
          },
          {
            key: 'cat-chaser-toys',
            label: 'Cat Chaser Toys',
          },
          {
            key: 'cat-soft-toys',
            label: 'Cat Soft Toys',
          },
          {
            key: 'cat-laser-toys',
            label: 'Cat Laser Toys',
          },
          {
            key: 'cat-tunnels',
            label: 'Cat Tunnels',
          },
          {
            key: 'cat-interactive-toys',
            label: 'Cat Interactive Toys',
          },
          {
            key: 'cat-kitten-toys',
            label: 'Cat Kitten Toys',
          },
        ],
      },
      {
        key: 'cat-clothing-accessories',
        label: 'Cat Clothing & Accessories',
        subCategories: [
          {
            key: 'all-cat-clothing-accessories',
            label: 'All Cat Clothing & Accessories',
          }
        ]
      },
      {
        key: 'cat-carriers',
        label: 'Cat Carriers',
        subCategories: [
          {
            key: 'all-cat-carriers',
            label: 'All Cat Carriers',
          }
        ]
      },
      {
        key: 'cat-beds',
        label: 'Cat Beds',
        subCategories: [
          {
            key: 'cat-igloo-and-cave-beds',
            label: 'Cat Igloo & Cave Beds',
          },
          {
            key: 'cat-mats-blankets-duvets-throw',
            label: 'Cat Mats, Blankets, Duvets, Throws',
          },
          {
            key: 'cat-radiator-beds',
            label: 'Cat Radiator Beds',
          },
          {
            key: 'cat-heated-beds',
            label: 'Cat Heated Beds',
          },
          {
            key: 'cat-soft-beds',
            label: 'Cat Soft Beds',
          },
        ],
      },
      {
        key: 'cat-bowls-and-feeders',
        label: 'Cat Bowls & Feeders',
        subCategories: [
          {
            key: 'cat-feeders',
            label: 'Cat Feeders',
          },
          {
            key: 'cat-drinking-fountains-and-bottles',
            label: 'Cat Drinking Fountains & Bottles',
          },
          {
            key: 'cat-fountains-accessories',
            label: 'Cat Fountains Accessories',
          },
          {
            key: 'cat-bowls',
            label: 'Cat Bowls',
          },
          {
            key: 'cat-feeding-mats',
            label: 'Cat Feeding Mats',
          },
          {
            key: 'cat-food-storage',
            lable: 'Cat Food Storage',
          },
        ],
      },
      {
        key: 'cat-grooming-and-care',
        label: 'Cat Grooming & Care',
        subCategories: [
          {
            key: 'cat-brushes-and-combs',
            label: 'Cat Brushes & Combs',
          },
          {
            key: 'cat-shampoos-and-bathing-products',
            label: 'Cat Shampoos & Bathing Products',
          },
          {
            key: 'cat-grooming-tools',
            label: 'Cat Grooming Tools',
          },
        ],
      },
      {
        key: 'kitten-care',
        label: 'Kitten Care',
        subCategories: [
          {
            key: 'kitten-beds',
            label: 'Kitten Beds',
          },
          {
            key: 'kitten-carriers-and-cages',
            label: 'Kitten Carriers & Cages',
          },
          {
            key: 'kitten-trees-and-scratchers',
            label: 'Kitten Trees & Scratchers',
          },
          {
            key: 'kitten-bowls-and-feeders',
            label: 'Kitten Bowls & Feeders',
          },
          {
            key: 'kitten-collars-and-harnesses',
            label: 'Kitten Collars & Harnesses',
          },
          {
            key: 'kitten-grooming',
            label: 'Kitten Grooming',
          },
          {
            key: 'kitten-health-and-hygiene',
            label: 'Kitten Health & Hygiene',
          },
          {
            key: 'kitten-litter-and-trays',
            label: 'Kitten Litter & Trays',
          },
          {
            key: 'kitten-toys',
            label: 'Kitten Toys',
          },
          {
            key: 'kitten-treats',
            label: 'Kitten Treats',
          },
        ],
      },
      {
        key: 'cat-collars-and-harnesses',
        label: 'Cat Collars & Harnesses',
        subCategories: [{
          key: 'all-cat-collars-and-harnesses',
          label: 'All Cat Collars & Harnesses',
        }],
      },
      {
        key: 'cat-supplements',
        label: 'Cat Supplements',
        subCategories: [
          {
            key: 'all-cat-supplements',
            label: 'All Cat Supplements',
          }
        ],
      },
    ],
  },
  // humans: {
  //   key: 'humans',
  //   label: 'Home & Humans',
  //   subCategories: [],
  // },
  otherPets: {
    key: 'otherPets',
    label: 'Other Pets',
    subCategories: [
      {
        key: 'smallAnimals',
        label: 'Small Animals',
        subCategories: [
          {
            key: 'smallAnimal_food-and-hay',
            label: 'Small Animal Food & Hay',
          },
          {
            key: 'smallAnimal_houses-and-bedding',
            label: 'Small Animal Houses & Bedding',
          },
          {
            key: 'smallAnimal_bowls-and-feeding-equipment',
            label: 'Small Animal Bowls & Feeding Equipment',
          },
          {
            key: 'smallAnimal_healthcare',
            label: 'Small Animal Healthcare',
          },
          {
            key: 'smallAnimal_toys-and-activities',
            label: 'Small Animal Toys & Activities',
          },
          {
            key: 'smallAnimal_travel',
            label: 'Small Animal Travel',
          },
          {
            key: 'smallAnimal_treats-and-gnaws',
            label: 'Small Animal Treats & Gnaws',
          },
          {
            key: 'smallAnimal_hutches-cages-and-runs',
            label: 'Small Animal Hutches, Cages & Runs',
          },
        ],
      },
      {
        key: 'birds',
        label: 'Birds',
        subCategories: [
          {
            key: 'petBird_food',
            label: 'Pet Bird Food',
          },
          {
            key: 'petBird_feeders',
            label: 'Pet Bird Feeders',
          },
          {
            key: 'petBird_cages-aviaries',
            label: 'Pet Bird Cages & Aviaries',
          },
          {
            key: 'petBird_healthcare',
            label: 'Pet Bird Healthcare',
          },
          {
            key: 'petBird_toys-treats',
            label: 'Pet Bird Toys & Treats',
          },
          {
            key: 'petBird_home-farming',
            label: 'Pet Bird Home Farming',
          },
        ],
      },
      {
        key:'food-storage',
        label:'Food Storage',
      },
      {
        key: 'reptiles',
        label: 'Reptiles',
        subCategories: [
          //           Reptile Food
          // Reptile Healthcare & Cleaning
          // Reptile Feeding Equipment
          // Reptile Bedding & Substrates
          // Reptile Vivarium Accessories,
          {
            key: 'petReptile_food',
            label: 'Pet Reptile Food',
          },
          {
            key: 'petReptile_healthcare&Cleaning',
            label: 'Pet Reptile Healthcare & Cleaning',
          },
          {
            key: 'petReptile_feeding-equipment',
            label: 'Pet Reptile Feeding Equipment',
          },
          {
            key: 'petReptile_bedding&Substrates',
            label: 'Pet Reptile Bedding & Substrates',
          },
          {
            key: 'petReptile_vivarium-accessories',
            label: 'Pet Reptile Vivarium Accessories',
          },
        ],
      },
      {
        key: 'fish',
        label: 'Fish',
        subCategories: [
          {
            key: 'petFish_tanks&Accessories',
            label: 'Pet Fish Tanks & Accessories',
          },
          {
            key: 'petFish_food',
            label: 'Pet Fish Food',
          },
          {
            key: 'petFish_treatments',
            label: 'Pet Fish Treatments',
          },
        ],
      },
      {
        key: 'equestrian-supplies',
        label: 'Equestrian Supplies',
        subCategories: [
          {
            key: 'all-equestrian-supplies',
            label: 'All Equestrian Supplies',}
        ],
      },
    ],
  },
};

const getSubCategories = () => {
  const list = [];

  const _pushSubCategories = (category, _subCategories, _list, collection, _mainCategory) =>
    _subCategories.forEach(_subCategory => {
      _list.push({
        ..._subCategory,
        category,
        collection,
        // since otherPets has nested subCategories, in order to track back mainCategory
        // we need to pass it to subCategory, main using in EditListingDetailsPanels of subCategory form
        // reason not changing category itself is due to topDesktopbar need to show nested sub link
        ...(_mainCategory && category !== _mainCategory ? { mainCategory: _mainCategory } : {}),
      });

      if (_subCategory?.subCategories) {
        _pushSubCategories(
          _subCategory.key,
          _subCategory.subCategories,
          _list,
          _subCategory.key,
          category
        );
      }
    });

  /**
   * Loop through the categories object and call the recursive function
   * for each category
   */
  Object.entries(CATEGORIES).forEach(([key, { subCategories }]) =>
    _pushSubCategories(key, subCategories, list, key, key)
  );

  return list;
};

const subCategories = getSubCategories();

export let filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectMutlipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: Object.values(CATEGORIES).map(({ key, label }) => ({
        key,
        label,
      })),
    },
  },
  {
    id: 'subcategory',
    label: 'Subcategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // Specify if the field depends on the specific
      // defined filter.
      dependOn: 'category', // filter id

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'beds', label: 'Beds', category: 'all', collection: 'beddings' },
        // { key: 'blankets', label: 'Blankets', category: 'all', collection: 'beddings' },
        // { key: 'bowl', label: 'Bowl', category: 'all', collection: 'feeding' },
        // { key: 'tray', label: 'Tray', category: 'all', collection: 'feeding' },
        // { key: 'feeder', label: 'Feeder', category: 'all', collection: 'feeding' },

        ...(subCategories.length && subCategories),
      ],
    },
  },
  {
    id: 'collection',
    label: 'Collection',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_collection'],

    // Specify if the filter should be hidden from the search page.
    hideFromSearchPage: true,

    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'food', label: 'Food' },
        // { key: 'beddings', label: 'Beddings' },
        // { key: 'feeding', label: 'Feeding' },
        // { key: 'treats', label: 'Treats' },
        // { key: 'toys', label: 'Toys' },
        // { key: 'collars-leads', label: 'Collars & Leads' },
        // { key: 'travel', label: 'Travel' },
        // { key: 'health', label: 'Health' },
        // { key: 'clothing', label: 'Clothing' },
        // { key: 'grooming', label: 'Grooming' },
        // { key: 'litters', label: 'Litters' },
        // { key: 'accessories', label: 'Accessories' },
      ],
    },
  },
  {
    id: 'brand',
    label: 'Brands',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [],
    },
  },
  {
    id: 'color',
    label: 'Color',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'red', label: 'Red', color: '#FF0000' },
        { key: 'green', label: 'Green', color: '#00FF00' },
        { key: 'blue', label: 'Blue', color: '#0000FF' },
        { key: 'yellow', label: 'Yellow', color: '#FFFF00' },
        { key: 'purple', label: 'Purple', color: '#800080' },
        { key: 'orange', label: 'Orange', color: '#FFA500' },
        { key: 'pink', label: 'Pink', color: '#FFC0CB' },
        { key: 'brown', label: 'Brown', color: '#A52A2A' },
        { key: 'cyan', label: 'Cyan', color: '#00FFFF' },
        { key: 'lime', label: 'Lime', color: '#00FF00' },
        { key: 'maroon', label: 'Maroon', color: '#800000' },
        { key: 'navy', label: 'Navy', color: '#000080' },
        { key: 'teal', label: 'Teal', color: '#008080' },
        { key: 'olive', label: 'Olive', color: '#808000' },
        { key: 'silver', label: 'Silver', color: '#C0C0C0' },
        { key: 'gray', label: 'Gray', color: '#808080' },
        { key: 'black', label: 'Black', color: '#000000' },
        { key: 'white', label: 'White', color: '#FFFFFF' },
      ],
    },
  },
  {
    id: 'size',
    label: 'Size',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'xxsmall', label: 'XXSmall' },
        { key: 'xsmall', label: 'XSmall' },
        { key: 'small', label: 'Small' },
        { key: 'medium', label: 'Medium' },
        { key: 'large', label: 'Large' },
        { key: 'xlarge', label: 'XLarge' },
        { key: 'xxlarge', label: 'XXLarge' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['category', 'subcategory', 'brand'],
};

export const discountCodeConfig = [
  { id: '6f642c35-8959-467b-8499-4cca55ba3748', code: 'NEWSLETTER10', discount: -10 },
];

// Function to update options asynchronously
const updateOptionsFromAPI = async () => {
  try {
    const list = await getAllBrands({ status: 'active' });

    // ensure result is sucessful and expect data structure
    if (list && list.data && Array.isArray(list.data) && list.data.length > 0) {
      const brandOptions = list.data
        // filter unexpected types
        .filter(brand => ['A-G', 'G-M', 'N-R', 'S-Z'].includes(brand.type))
        .map(brand => ({
          key: brand.key,
          label: brand.label,
          type: brand.type,
        }))
        // sort by label A->Z
        .sort((a, b) => a.label.localeCompare(b.label));

      filters = filters.map(filter => {
        if (filter.id === 'brand') {
          filter.config.options = brandOptions;
        }
        return filter;
      });
    }
  } catch (error) {
    console.error('Error fetching brands:', error);
  }
};

export { updateOptionsFromAPI };
